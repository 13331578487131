import gql from 'graphql-tag'


const docConfigResponse = `
  id type docType name parentId isDefault order
  options
  unitId unit {id name}
  inventoryId inventory {
    id type code name unitId
    unit {id name}
  }
  accountId account {id code name}
  paperId paper {id type code name}
  actionPlanId actionPlan {id name}
  riskAssessmentId riskAssessment {id name}
  targetGraphId targetGraph {id name}
  docCategoryId docCategory {id name}
  children {
    id type docType parentId
    qty amountRatio
    inventoryId inventory {
      id type code name unitId
      unit {id name}
    }
    accountId account {id code name}
    junctionChildren {
      id type name isDefault
    }
  }
`

export const LIST_DOC_CONFIG_MENU = (templateType) => gql`query LIST_DOC_CONFIG_MENU ($docType: String!) {
  menus: listDoc${templateType}DocConfigMenu (docType: $docType)
}`

export const LIST_DOC_CONFIG = (templateType) => gql`query LIST_DOC_CONFIG ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfig (docType: $docType, q: $q) {${docConfigResponse}}
}`

export const CREATE_DOC_CONFIG = (templateType) => gql`mutation CREATE_DOC_CONFIG ($docType: String!, $docConfigId: Int, $input: DocConfigInput!) {
  item: createDoc${templateType}DocConfig (docType: $docType, docConfigId: $docConfigId, input: $input) {${docConfigResponse}}
}`

export const CREATE_DOC_CONFIG_CHILDREN = (templateType) => gql`mutation CREATE_DOC_CONFIG_CHILDREN ($docType: String!, $input: DocConfigInput!) {
  item: createDoc${templateType}DocConfig (docType: $docType, input: $input) {
    id type docType parentId
    qty amountRatio
    inventoryId inventory {
      id type code name unitId
      unit {id name}
    }
    accountId account {id code name}
    junctionChildren {
      id type name isDefault
    }
  }
}`

export const DESTROY_DOC_CONFIG = (templateType) => gql`mutation DESTROY_DOC_CONFIG ($docType: String!, $docConfigId: Int!) {
  item: destroyDoc${templateType}DocConfig (docType: $docType, docConfigId: $docConfigId) {id}
}`

export const DP_ITEM_ACCOUNTS = (templateType) => gql`query DP_ITEM_ACCOUNTS ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownItemAccount (docType: $docType, q: $q) {
    id type name
  }
}`

export const DP_COST_ACCOUNTS = (templateType) => gql`query DP_COST_ACCOUNTS ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownCostAccount (docType: $docType, q: $q) {
    id type name
  }
}`

export const DP_EXPENSE_ACCOUNTS = (templateType) => gql`query DP_EXPENSE_ACCOUNTS ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfigDropdownExpenseAccount (docType: $docType, q: $q) {
    id type name
  }
}`
