import gql from 'graphql-tag'


const detailResponse = `
  id type name parentId
  qty price totalPrice
  unitId unit {id name}
  stocks {
    id qty docId parentId orderId isPrinted
    inventoryId inventory {
      id code name
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  ledgers {
    id docId stockId orderId amount
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  order: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id docId}
}`

export const CREATE_ORDER_TRANSFORM = (templateType) => gql`mutation CREATE_ORDER_TRANSFORM ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderTransformInput!) {
  order: createDoc${templateType}OrderTransform (docType: $docType, docId: $docId, input: $input) {${detailResponse}}
}`

export const LIST_TRANSFORM_INVENTORY = (templateType) => gql`query LIST_TRANSFORM_INVENTORY ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}TransformInventory (docType: $docType, q: $q) {
    id type name
    children {
      id type docType name parentId
      qty amountRatio
      inventoryId inventory {
        id type code name unitId
        unit {id name}
      }
      accountId account {id code name}
      junctionChildren {
        id type name isDefault
      }
    }
  }
}`
